import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ data, pageContext }) => {
    const post = data.markdownRemark;
    const { previous, next } = pageContext;
    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
                keywords={post.frontmatter.keywords}
                slug={post.fields.slug}
            />
            <main>
                <article>
                    <header className="blog-header">
                        <h1 className="blog-title">{post.frontmatter.title}</h1>
                        <small className="blog-meta">
                            {post.frontmatter.date} • ⏱{' '} {post.timeToRead} min
                            read</small>
                    </header>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    <footer />
                </article>
            </main>
            <ul
                style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    margin: 0,
                    padding: 0,
                }}
            >
                <li>
                    {previous && (
                        <Link className="button-primary" to={previous.fields.slug} rel="prev">
                            ← {previous.frontmatter.title}
                        </Link>
                    )}
                </li>
                <li>
                    {next && (
                        <Link className="button-primary" to={next.fields.slug} rel="next">
                            {next.frontmatter.title} →
                        </Link>
                    )}
                </li>
            </ul>
        </Layout>
    );
};

export const pageQuery = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            timeToRead
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                keywords
            }
            fields {
                slug
            }
        }
    }
`;
